import $ from 'jquery';

let $trigger, $html;
export function init() {
    $html = $('html');
    $trigger = $('.menu-trigger');
    $trigger.on('click', click);
}

export function destroy() {
    $trigger.off('click', click);
}

function click() {
    $trigger.toggleClass('is-active');
    $html.toggleClass('menu-open');
    $('.header-mobile')[0].scrollTo(0, 0);
}