import $ from 'jquery';

export function init() {
    onYouTubeIframeAPIReady();
}

window.onYouTubeIframeAPIReady = function() {
    $('.js-youtube').each(function(index, item) {
        item = $(item);
        if (!item.data('Youtube')) {
            item.data('Youtube', new Youtube(item));
        }
    });
};

class Youtube {
    constructor(item) {
        this.item = item;
        this.trigger = item.find('.js-youtube-trigger');
        this.iframe = item.find('iframe');
        this.trigger.on('click', this.click.bind(this));
        this.player = new YT.Player('player', {
            events: {
                'onStateChange': this.stateChange.bind(this)
            }
        });
    }

    click(e) {
        e.preventDefault();
        // this.player.playVideo();
        this.player.playVideo();
    }

    stateChange(event) {
        switch(event.data) {
            case 1: //Video playing
                this.item.addClass('is-video-active');
                this.item.removeClass('is-video-paused');
                break;
            case 0: //Video ended
            case 2: //Video paused
                this.item.addClass('is-video-paused');
                break;
        }
    }
}
