import $ from 'jquery';

let items;
export function init() {
    items = $('[data-scroll]')
    items.on('click', click);
}

export function destroy() {
    items.off('click', click);
}

function click(e) {
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $($(e.currentTarget).attr('href')).offset().top,
    }, 500)
}