import $ from 'jquery';

let $trigger, $html;
export function init() {
    $('.c-faq--question a').on('click', function (e) {
        e.preventDefault();

        $(this).parent()
            .next()
            .slideToggle(500);
    });
}

export function destroy() {
}