import $ from 'jquery';
import anime from 'animejs';
import './smoothstate.js';
import fancybox from '@fancyapps/fancybox';


//Animations
import enlarge from './enlarge';
import fade from './fade';
import clip from './clip';

//Scripts
import * as menu from './menu';
import * as scrollTo from './scrollto';
import * as embed from './embed';
import * as form from './form';
import * as faq from './faq';
import appear from './appear';
import cover from './cover';

let prev;
let items, item, timeline, currentEl, newPage, transition = 'fade', theme, direction;
let $body, $html;
$(function () {
    $html = $('html');
    $body = $('body');
    init(false);
    // Default
    $('#transition').smoothState({

        scroll: false,
        anchors: '.js-transition',
        prefetch: true,
        forms: false,

        //The function to run before a page load is started.
        onBefore: function ($currentTarget, $container) {
            currentEl = $currentTarget;
            transition = $currentTarget.data('transition');
            direction = $currentTarget.data('direction');

            scrollTo.destroy();
            menu.destroy();
            form.destroy();
            faq.destroy();
        },

        //The function to run when the requested content is ready to be injected into the page. 
        //This is when the page's contents should be updated.
        onReady: {
            duration: 500,
            render: function ($container, $newContent, popped) {
                if (popped) {
                    transition = 'fade';
                }

                theme = $newContent.filter('main').data('theme');
                switch (transition) {
                    case 'enlarge':
                        enlarge(currentEl, $container, $newContent);
                        break;
                    case 'clip':
                        clip(currentEl, $container, $newContent, direction);
                        break;
                    case 'fade':
                    default:
                        fade(currentEl, $container, $newContent);
                        break;
                }
            }
        },

        //This is when to re-initialize any plugins needed by the page.
        onAfter: function ($container, $newContent) {
            prev = window.location.href;
            $container.find('footer').html($newContent.filter('footer'))


            if ($(window).width() <= 768) {
                $container.find('#page-nav').html($newContent.filter('#page-nav'));
                setTimeout(() => {
                    $html.removeClass('menu-open');
                }, 50)

            } else {
                $container.find('#page-nav').html($newContent.filter('#page-nav'));


            }

            if (transition == 'enlarge') {
                anime({
                    targets: $('.header')[0],
                    easing: 'easeOutQuad',
                    opacity: [0, 1],
                    duration: 1000,
                    delay: 750
                })
            }

        }
    });

});

export function init(async = true) {
    scrollTo.init();
    menu.init();
    embed.init();
    form.init();
    faq.init();
    appear();
    cover()

    if (async) {
        $body.removeClass('t-alt');
        if (theme) {
            $body.addClass(theme);
        }
    }
}