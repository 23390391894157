import anime from 'animejs';
import { init } from './transition';
import $ from 'jquery';


const ANIM_SPEED = 1250;
const ANIM_EASING = [0.86, 0, 0.07, 1];

export default function enlarge($target, $container, $newContent) {
    let timeline = anime.timeline();

    if($target.length && $newContent.find('.c-hero__btn').length) {
        timeline.add({
            targets: 'html, body',
            scrollTop: $target.offset().top,
            easing: ANIM_EASING,
            duration: ANIM_SPEED,
        });
        timeline.add({
            targets: '.c-hero__btn',
            opacity: [1, 0],
            easing: ANIM_EASING,
            duration: ANIM_SPEED / 2,
            offset: `-=${ANIM_SPEED}`,
        });
        
        timeline.add({
            targets: $target[0],
            minHeight: $(window).outerHeight(),
            duration: ANIM_SPEED,
            easing: ANIM_EASING,
            offset: `-=${ANIM_SPEED}`,
            complete: (anim) => {
                window.scrollTo(0,0);
                $container.html($newContent);
                init();
            }
        });
    
        timeline.add({
            targets: $newContent.find('.c-hero__btn')[0],
            opacity: [0, 1],
            easing: 'linear',
            duration: 500,
        });
    } else {
        timeline
        .add({
            targets: $container.find('main')[0],
            opacity: [1, 0],
            easing: 'linear',
            duration: 450,
            complete: () => {
                $container.find('main').html($newContent.filter('main.page-wrap'));
                window.scrollTo(0,0);
                init();
            }
        })
        .add({
            targets: $container.find('main')[0],
            easing: 'linear',
            duration: 450,
            opacity: [0, 1],
        })
    }
    

   
}

