import $ from 'jquery';

var html = document.getElementsByTagName('html')[0];
var cover;

export default function init() {
    cover = $('.js-cover');
    $(window).on('resize', resize);
    resize();
}

function resize() {
    html.setAttribute("style", `--cover-height: ${cover.outerHeight(true) * -1}px`);
}