import anime from 'animejs';
import $ from 'jquery';
import { init } from './transition';

let $body;
export default function clip($target, $container, $newContent, direction) {

    let timeline = anime.timeline();
    let $body = $('body');
    let pageOld = $container.find('main.page-wrap').clone();
    let pageNew = $newContent.filter('main.page-wrap');
    let placeholder = $('<div class="transition-placehold" />');


    //Add old page to placeholder div
    placeholder.html(pageOld);
    $body.append(placeholder);

    //Add new content to page
    $container.find('main').html($newContent.filter('main.page-wrap').html());
    
    if(direction == -1) {
        placeholder.addClass('is-clipping is-clipping--reverse');
    } else {
        placeholder.addClass('is-clipping');
    }

    setTimeout(() => {
        $('html, body').animate({
            scrollTop: 0
        }, 250);
        placeholder.css({
            'z-index': '-1'
        })
        setTimeout(() => {
            placeholder.remove();
            init();
        }, 150)
        
    }, parseInt(placeholder.css('animation-duration')) * 1000);

    
}