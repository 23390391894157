var enterView = require('./enterView');
import anime from 'animejs';

export default function init() {
    enterView({
        selector: '.js-appear',
        offset: .15,
        once: true,

        enter: function(el) {
            anime({
                targets: el,
                opacity: [0, 1],
                easing: 'linear',
            })
        }
    });    
}