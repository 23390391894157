import anime from 'animejs';
import $ from 'jquery';
import { init } from './transition';

const ANIM_SPEED = 450;
const ANIM_EASING = 'linear';

export default function fade($target, $container, $newContent) {
    let timeline = anime.timeline();
    
    
    timeline
    .add({
        targets: $container.find('main')[0],
        opacity: [1, 0],
        easing: ANIM_EASING,
        duration: ANIM_SPEED,
        complete: () => {
            $container.find('main').html($newContent.filter('main.page-wrap'));
            window.scrollTo(0,0);
            init();
        }
    })
    .add({
        targets: $container.find('main')[0],
        easing: ANIM_EASING,
        duration: ANIM_SPEED,
        opacity: [0, 1],
    })
}