import $ from 'jquery';
import html2canvas from 'html2canvas';

var trigger, fields, extraFields, printEl, mailEl;

export function init() {
    fields = $('.person-fields-co');
    extraFields = $('.props-participant-extra');
    trigger = $('.c-form-btn--participant');
    printEl = $('#trigger-print');
    mailEl = $('#trigger-mail');
    trigger.on('click', click);

    $('[data-fancybox]').each(function() {
        let el = this;
        $(this).fancybox({
            beforeShow: function(instance, slide) {                
                let currEl = $(slide.src);
                if(el.dataset.title) {
                    currEl.find('h2').text(el.dataset.title);
                    currEl.find('#formtype').val(el.dataset.title);
                }
            }
        })
    })
    

}

export function destroy() {
    trigger.off('click', click);
    printEl.off('click', print);
    fields.removeClass('active');
    extraFields.removeClass('active');

}

function click(e) {
    e.preventDefault();
    fields.addClass('active');
    extraFields.addClass('active');
}

function print(e) {
    e.preventDefault();
    window.print();
}

function mail(e) {
    // e.preventDefault();
    // let target = document.querySelector("#print-target");
    // let inp = $('.')
    // html2canvas(target).then(canvas => {
    //     onrendered: (canvas) => {
    //         console.log(canvas)
    //     }
    // });
}